import React, { useState, useEffect } from 'react';
import '../../../css/Form.css'
import { GrSend } from "react-icons/gr";
import { TiDelete } from "react-icons/ti";
import { RiStickyNoteAddFill } from "react-icons/ri";
import { db } from '../../FireBase';
import { collection, addDoc, doc, getDoc, setDoc, runTransaction } from 'firebase/firestore';
import { auth } from '../../FireBase';
import emailjs from 'emailjs-com';

export default function Formulario() {
  const [nipleCount, setNipleCount] = useState(0);
  const [nipleData, setNipleData] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [inputDisabled, setInputDisabled] = useState(false);
  const [, setTicketNumber] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [generatedTicket, setGeneratedTicket] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [istextchange, settextchange] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [Days, setDays] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  const [observacion, setObservacion] = useState('');




  useEffect(() => {
    const fetchTicketNumber = async () => {
      const ticketRef = doc(db, 'ticketCounter', 'contador');
      const ticketDoc = await getDoc(ticketRef);
      if (ticketDoc.exists()) {
        const data = ticketDoc.data();
        setTicketNumber(data.numero || 0);
      } else {
        console.log("No existe el documento del contador, creando uno nuevo.");
        await setDoc(ticketRef, { numero: 0 });
        setTicketNumber(0);
      }

      const user = auth.currentUser;
      if (user) {
        setUserEmail(user.email); 
      }
    };
    fetchTicketNumber();
  }, []);

  const handleAdd = () => {
    const count = parseInt(inputValue, 10);
    if (count > 0) {
      setNipleCount(count);
      setNipleData(Array(count).fill({
        tipoMaterial: '', diametro: '', longitud: '', sch: '', entrada: '', salida: '',
        codigoTuberia: '', codigoNiple: '', cantidad: '', valorUnidad: '', bodega: ''
      }));
      setInputDisabled(true);
    }
  };

  const handleRemove = () => {
    setNipleCount(0);
    setNipleData([]);
    setInputDisabled(false);
  };

  const dateday = (e) => {
    const fecha = e.target.value;
    if (fecha) {
      const fechaActual = new Date();
      const fechaEstimada = new Date(fecha);
      const time = fechaEstimada - fechaActual;
      const Days = Math.ceil(time / (1000 * 60 * 60 * 24));
      setDays(Days);
    }
  };


  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedNipleData = [...nipleData];
    updatedNipleData[index] = { ...updatedNipleData[index], [name]: value };
    setNipleData(updatedNipleData);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handletextchange = (e) => {
    const regex = /^[A-Za-z\s]*$/;
    if (regex.test(e.target.value)) {
      settextchange(e.target.value.toUpperCase());
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const selectedSucursal = e.target.tipoSucursal.value;

    if (selectedSucursal === '') {
      alert('Por favor seleccione una opción.');
      setIsSubmitting(false);
      return;
  }

    if (selectedValue === '') {
      alert('Por favor seleccione una opcion.');
      setIsSubmitting(false);
      return;
      
    }

    const solicitante = e.target[0].value;
    const email = userEmail;
    const cliente = e.target[2].value;
    const pedido = e.target[3].value;
    const fecha = e.target[4].value;

    const formData = {
      cliente: cliente,
      estado: "solicitado",
      pedido: pedido,
      solicitante: solicitante,
      email: email,
      fecha: fecha,
      dias: Days,
      observacion1: observacion,
      detalles: nipleData.map(niple => ({
        cantidad: Number(niple.cantidad) || 0,
        codigoTuberia: niple.codigoTuberia || '',
        codigoNiple: niple.codigoNiple || '',
        valorUnidad: Number(niple.valorUnidad) || 0,
        bodega: niple.bodega || '',
        descripcion: `NIPLE ${niple.tipoMaterial} DE ${niple.diametro}" X ${niple.longitud}${niple.unidad === 'metros' ? ' MTS' : '"'} SCH: ${niple.sch} ${niple.entrada} X ${niple.salida}` || '',
      })),
    };

  // Define a mapping of sucursales to their respective ticket counters
  const ticketCounters = {
    YUMBO: 'ticketCounterN40',
    BARRANQUILLA: 'ticketCounter',
    CARTAGENA: '',
    // Add more mappings if needed
  };

  // Get the appropriate ticket counter document reference based on selectedSucursal
  const ticketRef = doc(db, ticketCounters[selectedSucursal] || 'ticketCounter', 'contador');

    try {
      // Transacción para actualizar el número de ticket
      const newTicketNumber = await runTransaction(db, async (transaction) => {
        const ticketDoc = await transaction.get(ticketRef);
        if (!ticketDoc.exists()) {
          throw new Error("Documento del contador no encontrado.");
        }

        const currentTicketNumber = ticketDoc.data().numero;
        const updatedTicketNumber = currentTicketNumber + 1;

        // Actualizar el número del contador
        transaction.update(ticketRef, { numero: updatedTicketNumber });

        return updatedTicketNumber;
      });

        // Definir la colección a la que se enviarán los datos según la sucursal seleccionada
        let collectionName = "RequestNiples"; // Default
        if (selectedSucursal === "BARRANQUILLA") {
            collectionName = "RequestNiples"; // Colección para Barranquilla
        } else if (selectedSucursal === "YUMBO" || selectedSucursal === "CARTAGENA") {
            collectionName = "RequestNiples40"; // Colección para Yumbo y Cartagena
        }

        // Agregar el documento con el número de ticket generado
        const docRef = await addDoc(collection(db, collectionName), {
            ...formData,
            numeroTicket: newTicketNumber,
        });

      console.log("Documento escrito con ID: ", docRef.id);
      setGeneratedTicket(newTicketNumber);

      // Mostrar modal y guardar número de ticket generado
      setModalOpen(true);

      // Limpiar el formulario
      setInputValue('');
      setNipleCount(0);
      setNipleData([]);
      setObservacion([])
      setInputDisabled(false);

      emailjs.init('TTx4rkAoVX9qSvDRu'); // Aquí pones tu API Key pública

      emailjs.send('service_luojev4', 'template_5d0arhw', {
        user_name: 'Nombre del Solicitante',  // Esto será reemplazado en la plantilla
        message: 'Tienes una notificación nueva de Niple',
        to_email: 'auxlogisticabq@tuvacol.com'   // Solo si lo necesitas en la plantilla
    })
    .then((response) => {
        console.log('Correo enviado correctamente', response.status, response.text);
    }, (error) => {
        console.error('Error al enviar el correo:', error);
    });

    } catch (e) {
      console.error("Error añadiendo el documento: ", e);
    } finally {
      setIsSubmitting(false);
    }
  };

  

  return (
    <div className="formulario-container">
      <h2 className='title2'>Solicitud De Elaboracion De Niple </h2>
      <div className="card">
        <form onSubmit={handleSubmit}>
          <div className="form-section">
            <h2>Información Del Solicitante</h2>
            <div className="form-grid">
              <div className="form-group">
                <label>Nombre Solicitante:</label>
                <input type="text"
                  value={istextchange}
                  onChange={handletextchange} style={{ textTransform: 'uppercase' }} required />
              </div>
              <div className="form-group">
                <label>Correo Electrónico:</label>
                <input type="email" value={userEmail} required disabled />
              </div>
              <div className="form-group">
                <label>Cliente:</label>
                <input type="text" required style={{ textTransform: 'uppercase' }} />
              </div>
              <div className="form-group">
                <label>Número De Pedido:</label>
                <input type="text" required name="pedido" style={{ textTransform: 'uppercase' }} />
              </div>
              <div className="form-group">
                <label>Fecha Estimada</label>
                <input type="date" name="fecha"
                  min={new Date().toISOString().split("T")[0]}
                  onChange={dateday} required />
              </div>
              <div className="form-group">
                <label>Sucursal Designada:</label>
                <select name="tipoSucursal">
                          <option value="">Seleccione</option>
                          <option value="BARRANQUILLA">SUCURSAL BARRANQUILLA</option>
                          <option value="YUMBO" disabled>SUCURSAL YUMBO</option>
                          <option value="CARTAGENA" disabled>SUCURSAL CARTAGENA</option>
                          <option value="FUNZA" disabled>SUCURSAL FUNZA</option>
                        </select>
              </div>
            </div>
          </div>

          <div className="form-section">
            <h2>Agregar Número de Niples</h2>
            <div className="list-header">
              <label>Número de Niples:</label>
              <input
                type="number"
                value={inputValue}
                onChange={handleInputChange}
                disabled={inputDisabled}
                className='custom-input'
                required
              />
              <button type="button" onClick={handleAdd} disabled={inputDisabled} className="button button-blue">
                <RiStickyNoteAddFill className='icon-send1' />
              </button>
              <button type="button" onClick={handleRemove} className="button button-red">
                <TiDelete className='icon-send1' />
              </button>
            </div>
          </div>

          {nipleCount > 0 && (
            <div className="form-section">
              <h2>Características Del Niple</h2>
              <div className="niple-forms">
                {nipleData.map((niple, index) => (
                  <div key={index} className="niple-form">
                    <h3>Niple{index + 1}</h3>
                    <div className="niple-form-grid">
                      <div className="niple-form-group">
                        <label>Tipo de Material:</label>
                        <select name="tipoMaterial" value={niple.tipoMaterial} onChange={(e) => handleChange(index, e)} required>
                          <option value="">Seleccione</option>
                          <option value="A/C">A/C</option>
                          <option value="A/INOX 304L">A/I 304L</option>
                          <option value="A/INOX 316L">A/I 316L</option>
                          <option value="GALV.">GALV.</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Diámetro:</label>
                        <select name="diametro" value={niple.diametro} onChange={(e) => {
                          setSelectedValue(e.target.value);
                          handleChange(index, e);
                        }} required>
                          <option value="">-</option>
                          <option value="1/8">1/8"</option>
                          <option value="1/4">1/4"</option>
                          <option value="3/8">3/8"</option>
                          <option value="1/2">1/2"</option>
                          <option value="3/4">3/4"</option>
                          <option value="1">1"</option>
                          <option value="1 1/4">1 1/4"</option>
                          <option value="1 1/2">1 1/2"</option>
                          <option value="2">2"</option>
                          <option value="2 1/2">2 1/2"</option>
                          <option value="3">3"</option>
                          <option value="4">4"</option>
                          <option value="5">5"</option>
                          <option value="6">6"</option>
                          <option value="8">8"</option>
                          <option value="10">10"</option>
                          <option value="12">12"</option>
                          <option value="14">14"</option>
                          <option value="16">16"</option>
                          <option value="20">20"</option>
                          <option value="24">24"</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Unidad de Longitud:</label>
                        <select
                          name="unidad"
                          value={niple.unidad || ''}
                          onChange={(e) => handleChange(index, e)}
                          required
                        >
                          <option value="">Seleccione</option>
                          <option value="pulgadas">Pulgadas</option>
                          <option value="metros">Metros</option>
                        </select>
                      </div>

                      <div className="niple-form-group">
                        <label>Longitud:</label>
                        {niple.unidad === "metros" ? (
                          <input
                            type="number"
                            name="longitud"
                            value={niple.longitud}
                            onChange={(e) => handleChange(index, e)}
                            onKeyPress={(e) => {
                              if (!/[0-9.]/.test(e.key)) {
                                e.preventDefault();
                              }
                            }}
                            required
                            min="0"
                            step="0.001"
                            placeholder="Ingrese longitud en metros"
                          />
                        ) : (
                          <select
                            name="longitud"
                            value={niple.longitud}
                            onChange={(e) => handleChange(index, e)}
                            required
                          >
                            <option value="">Seleccione</option>
                            <option value="1/8">1/8"</option>
                            <option value="1/4">1/4"</option>
                            <option value="3/8">3/8"</option>
                            <option value="1/2">1/2"</option>
                            <option value="3/4">3/4"</option>
                            <option value="1">1"</option>
                            <option value="1 1/4">1 1/4"</option>
                            <option value="1 1/2">1 1/2"</option>
                            <option value="2">2"</option>
                            <option value="2 1/2">2 1/2"</option>
                            <option value="3">3"</option>
                            <option value="4">4"</option>
                            <option value="5">5"</option>
                            <option value="6">6"</option>
                            <option value="8">8"</option>
                            <option value="10">10"</option>
                            <option value="12">12"</option>
                            <option value="14">14"</option>
                          </select>
                        )}
                      </div>                      
                      <div className="niple-form-group">
                        <label>SCH:</label>
                        <select name="sch" value={niple.sch} onChange={(e) => handleChange(index, e)} required>
                          <option value="">Seleccione</option>
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="40">40</option>
                          <option value="80">80</option>
                          <option value="120">120</option>
                          <option value="140">140</option>
                          <option value="160">160</option>
                          <option value="XXS">XXS</option>
                          <option value="XS">XS</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Entrada:</label>
                        <select name="entrada" value={niple.entrada} onChange={(e) => handleChange(index, e)} required>
                          <option value="">Seleccione</option>
                          <option value="NPT">NPT</option>
                          <option value="RANURA">RANURA</option>
                          <option value="PP">PP</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Salida:</label>
                        <select name="salida" value={niple.salida} onChange={(e) => handleChange(index, e)} required>
                          <option value="">Seleccione</option>
                          <option value="NPT">NPT</option>
                          <option value="RANURA">RANURA</option>
                          <option value="PP">PP</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Cantidad:</label>
                        <input type="number" name="cantidad" value={niple.cantidad} onChange={(e) => handleChange(index, e)}
                          onKeyDown={(e) => {
                            if (e.key === ',' || e.key === '-' || e.key === 'e' || e.key === '.') {
                              e.preventDefault();
                            }
                          }} required />
                      </div>
                      <div className="niple-form-group">
                        <label>Precio de venta</label>
                        <input type="number" name="valorUnidad" value={niple.valorUnidad} onChange={(e) => handleChange(index, e)}
                          onKeyDown={(e) => {
                            if (e.key === ',' || e.key === '-' || e.key === 'e' || e.key === '.' || e.key === '+') {
                              e.preventDefault();
                            }
                          }} required />
                      </div>
                      <div className="niple-form-group">
                        <label>Bodega:</label>
                        <select name="bodega" value={niple.bodega} onChange={(e) => handleChange(index, e)} required>
                          <option value>Seleccione</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                        </select>
                      </div>
                      <div className="niple-form-group">
                        <label>Código de Tubería:</label>
                        <input type="text" name="codigoTuberia" value={niple.codigoTuberia} onChange={(e) => handleChange(index, e)}
                          style={{ textTransform: 'uppercase' }} required />
                      </div>
                      <div className="niple-form-group">
                        <label>Código de Niple:</label>
                        <input type="text" name="codigoNiple" value={niple.codigoNiple} onChange={(e) => handleChange(index, e)}
                          style={{ textTransform: 'uppercase' }} required />
                      </div>
                    </div>
                  </div>
                ))}
                {/* Observación */}
                <div className="niple-form-group">
                  <label>Observación:</label>
                  <textarea
                    value={observacion}
                    onChange={(e) => setObservacion(e.target.value)}
                    style={{ textTransform: 'uppercase' }}
                    placeholder="Ingrese una observación..."
                    rows="3"
                  />
                </div>
              </div>
              <br></br>
              <button type="submit" className="button button-submit" disabled={isSubmitting}>
                {isSubmitting ? (
                  <span className="loader"></span> 
                ) : (
                  <>
                    <GrSend className='icon-send' />Enviar
                  </>
                )}
              </button>
            </div>
          )}
        </form>
      </div>

      {/* Modal para mostrar el ticket generado */}
      {modalOpen && (
        <div className="modal-ticket">
          <div className="modal-content-ticket">
            <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
            <h2>Ticket Generado</h2>
            <p>El número de ticket generado es: <strong>N-{generatedTicket}</strong></p>
            <button onClick={() => setModalOpen(false)} className='Cerrar-ticket'>Cerrar</button>
          </div>
        </div>
      )}
    </div>
  );
}






