// src/components/Header.js
import React, { useEffect, useState, useCallback } from 'react'; // Import useCallback
import { useNavigate } from 'react-router-dom';
import '../css/Header.css';
import tuvacol_logo from '../assets/logo_tuvacol.png';
import { RiLogoutCircleLine } from "react-icons/ri"; 
import { auth } from '../pages/FireBase';
import { signOut } from 'firebase/auth';
import ConfirmationModal from '../Components/ConfirmationModal'; // Import the modal component

export default function Header({ title }) {
  const [userName, setUserName] = useState(null);
  const [userColor, setUserColor] = useState('#DB4437');
  const [isModalVisible, setModalVisible] = useState(false); // State for modal visibility
  const navigate = useNavigate();

  // Use useCallback to memoize getUserColor
  const getUserColor = useCallback((name) => {
    const colors = {
      A: '#EA4335', // Red
      B: '#FBBC05', // Yellow
      C: '#34A853', // Green
      D: '#4285F4', // Blue
      E: '#DB4437', // Gmail red
      F: '#F4B400', // Yellow
      G: '#0F9D58', // Green
      H: '#DB4437', // Gmail red
      I: '#4285F4', // Blue
      J: '#F4B400', // Yellow
      K: '#EA4335', // Red
      L: '#0F9D58', // Green
      M: '#DB4437', // Gmail red
      N: '#34A853', // Green
      O: '#4285F4', // Blue
      P: '#F4B400', // Yellow
      Q: '#EA4335', // Red
      R: '#FBBC05', // Yellow
      S: '#34A853', // Green
      T: '#4285F4', // Blue
      U: '#DB4437', // Gmail red
      V: '#F4B400', // Yellow
      W: '#EA4335', // Red
      X: '#FBBC05', // Yellow
      Y: '#34A853', // Green
      Z: '#4285F4', // Blue
    };

    const initial = getInitials(name);
    return colors[initial] || '#DB4437'; // Return color or default color
  }, []);

  const getInitials = (name) => {
    return name ? name.charAt(0).toUpperCase() : '';
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserName(user.displayName || user.email);
        setUserColor(getUserColor(user.displayName || user.email));
      } else {
        setUserName(null);
        setUserColor('#DB4437');
      }
    });
    return () => unsubscribe();
  }, [getUserColor]); // Include getUserColor as a dependency

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate('/');
      })
      .catch((error) => {
        console.error("Error al cerrar sesión:", error);
      });
  };

  const handleSignOutClick = () => {
    setModalVisible(true); // Show modal on click
  };

  // Handle confirmation from modal
  const handleConfirmSignOut = () => {
    handleSignOut();
    setModalVisible(false); // Hide modal after confirmation
  };

  const handleCancelSignOut = () => {
    setModalVisible(false); // Hide modal on cancel
  };

  return (
    <header className="dashboard-header">
      <h1 className='titleheader'>{title}</h1>
      <div className="header-container">
        <img src={tuvacol_logo} alt="Logo" className="logo1" />
        
        {/* Mostrar correo e ícono circular */}
        <div className="user-info">
          {userName && (
            <div className="user-details">
              <div className="user-icon-circle" style={{ backgroundColor: userColor }}>
                {getInitials(userName)}
              </div>
              <span className="user-email">
                {userName}
              </span>
            </div>
          )}
        </div>

        <button className="back-button" onClick={handleSignOutClick}>
          <RiLogoutCircleLine size={30} />
        </button>
      </div>

      {/* Confirmation Modal */}
      <ConfirmationModal 
        isVisible={isModalVisible} 
        onConfirm={handleConfirmSignOut} 
        onCancel={handleCancelSignOut} 
      />
    </header>
  );
}
