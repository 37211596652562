// src/components/ConfirmationModal.js
import React, { useState } from 'react';
import '../css/Header.css'; // Create this CSS file for styling
import Loader from './Loader';

const ConfirmationModal = ({ isVisible, onConfirm, onCancel }) => {
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true); // Show loader

    // Simulate a delay for the loader (e.g., 2 seconds)
    await new Promise((resolve) => setTimeout(resolve, 2000));

    await onConfirm(); // Perform the action
    setLoading(false); // Hide loader
  };

  if (!isVisible) return null;

  return (
    <div className="modal-overlay1">
      <div className="modal-content1">
        {loading ? (
          <Loader />
        ) : (
          <>
            <h2 className='modal-text1'>¿Está seguro de que quiere cerrar sesión?</h2>
            <div className="modal-buttons1">
              <button onClick={handleConfirm}>Sí</button>
              <button onClick={onCancel}>No</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ConfirmationModal;
