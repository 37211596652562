import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDKxTfpiZoHA3jTOkLRYseyCgBQ9j0Z7SY",
    authDomain: "web-service-tuvacoloptimize.firebaseapp.com",
    projectId: "web-service-tuvacoloptimize",
    storageBucket: "web-service-tuvacoloptimize.appspot.com",
    messagingSenderId: "1055234327674",
    appId: "1:1055234327674:web:66256f7b01c394bc5167c3",
    measurementId: "G-1N04JMFMWF"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { db, auth };