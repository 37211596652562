import React, { useState } from 'react';
import Board from '../pages/10/Board/Board'; // Panel para Barranquilla
import BoardY from '../pages/40/BoardY'; // Panel para Yumbo
import BoardC from '../pages/Board/Sedes/BoardC'; // Panel para Cartagena
import BoardF from '../pages/Board/Sedes/BoardF'; // Panel para Funza
import '../css/Ubicaciones.css';
import { BiArrowBack } from "react-icons/bi";


export default function NipleOption() {
  const [activeLocation, setActiveLocation] = useState(null);

  // Función para renderizar la tarjeta de ubicación
  const renderCard = (location) => (
    <div className="location-card" key={location}>
      <div className="location-name">{location}</div>
      <button
        className="location-button"
        onClick={() => setActiveLocation(location)}
        disabled={location === 'SUCURSAL DE YUMBO (PRONTO)' || location === 'SUCURSAL DE CARTAGENA (PRONTO)' || location === 'SUCURSAL DE FUNZA (PRONTO)'}

      >
        Mostrar Panel
      </button>
    </div>
  );

  const renderBoard = () => {
    switch (activeLocation) {
      case 'SUCURSAL DE BARRANQUILLA':
        return <Board />;
      case 'SUCURSAL DE YUMBO (PRONTO)':
        return <BoardY />;
      case 'SUCURSAL DE CARTAGENA (PRONTO)':
        return <BoardC />;
      case 'SUCURSAL DE FUNZA (PRONTO)':
        return <BoardF />;
      default:
        return null;
    }
  };

  // Función para volver a la lista de ubicaciones
  const handleBack = () => {
    setActiveLocation(null);
  };

  return (
    <div className="ubicaciones-page">
      <div className="ubicaciones-container">
        {activeLocation ? (
          <div className="board-container1">
            <button className="back-button1" onClick={handleBack}>
              <BiArrowBack fontSize={20} className="ico" />
            </button>

            {renderBoard()} {/* Renderiza el panel correspondiente */}
          </div>
        ) : (
          <div className="location-buttons">
            {['SUCURSAL DE BARRANQUILLA', 'SUCURSAL DE YUMBO (PRONTO)', 'SUCURSAL DE CARTAGENA (PRONTO)', 'SUCURSAL DE FUNZA (PRONTO)'].map((location) =>
              renderCard(location)
            )}
          </div>
        )}
      </div>
    </div>
  );
}
