// src/components/Loader.js
import React from 'react';
import '../css/Header.css'; // Create this CSS file for styling

const Loader = () => {
  return (
    <div className="loader-overlay-sesion">
      <div className="loader-sesion"></div>
    </div>
  );
};

export default Loader;