import React, { useState, useEffect } from 'react';
import { FiDownload } from "react-icons/fi";
import '../../../css/Board.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { db } from '../../FireBase';
import { MdNavigateNext, MdArrowBackIos } from "react-icons/md";
import { collection, onSnapshot, updateDoc, doc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import logo from '../../../assets/logo_tuvacol1.png';
import firmaRevisa from '../../../assets/firma_shirly.png';
import firmaRecibe from '../../../assets/firma_mario.png';

export default function Panel() {
  const [orders, setOrders] = useState([]);
  const [activeOrderIndex, setActiveOrderIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // Estado para la página actual
  const ordersPerPage = 5; // Órdenes por página
  const [userEmail, setUserEmail] = useState(null);


  const permittedEmails = ['gestorabastecimiento@tuvacol.com', 'analistainvbql@tuvacol.com', 'controlinvenbq@tuvacol.com'];


  useEffect(() => {
    const auth = getAuth();
    const unsubscribeAuth = auth.onAuthStateChanged(user => {
      if (user) {
        setUserEmail(user.email); // Almacenar el correo del usuario que ha iniciado sesión
      }
    });

    const unsubscribe = onSnapshot(collection(db, "RequestPaint"), (snapshot) => {
      const ordersData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const sortedOrders = ordersData.sort((a, b) => {
        // Asignar prioridad a los estados
        const priority = { SOLICITADO: 1, APROBADO: 2, COMPLETADO: 3, 'AJUSTE INVENTARIO': 4, RECHAZADO: 5 };

        // Comparar por estado
        const stateComparison = priority[a.estado.toUpperCase()] - priority[b.estado.toUpperCase()];
        if (stateComparison !== 0) {
          return stateComparison;  // Si los estados son diferentes, se ordena por estado
        }

        // Si los estados son iguales, ordenar por número de ticket
        const ticketA = a.numeroTicket || 0;
        const ticketB = b.numeroTicket || 0;

        return ticketA - ticketB;
      });

      setOrders(sortedOrders);
    });

    return () => {
      unsubscribe();
      unsubscribeAuth();
    };
  }, []);


  // Función para actualizar el estado en Firebase
  const updateOrderState = async (orderId) => { // Mover la definición de la función aquí
    const orderDoc = doc(db, "RequestPaint", orderId);
    try {
      await updateDoc(orderDoc, { estado: 'ajuste inventario' });
      console.log(`Estado de la orden ${orderId} actualizado a AJUSTE INVENTARIO`);
    } catch (error) {
      console.error("Error al actualizar el estado de la orden:", error);
    }
  };


  const generatePDF = (order) => {
    if (!order) {
      console.error("Error: Los detalles de la orden no están disponibles.");
      return;
    }

    const doc = new jsPDF();
    const margin = 10;

    // Añadir el logo
    doc.addImage(logo, 'PNG', margin, margin, 40, 20);

    // Título centrado
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 51, 153);

    const pageWidth = doc.internal.pageSize.getWidth();
    let text = "ORDEN PARA ELABORACIÓN DE";
    let textWidth = doc.getTextWidth(text);
    doc.text(text, (pageWidth - textWidth) / 2, 28);

    text = "TRABAJOS EN TALLER";
    textWidth = doc.getTextWidth(text);
    doc.text(text, (pageWidth - textWidth) / 2, 34);

    // Añadir número de ticket
    doc.setFontSize(10);
    doc.setTextColor(0, 0, 0);
    doc.text(`Número de Ticket: P-${order.numeroTicket}`, margin, 40);

    // Subtítulo y versión del documento
    doc.setFontSize(10);
    doc.text("Versión: 05", pageWidth - margin - 40, 15);
    doc.text("Vigente desde: 24-abr-2019", pageWidth - margin - 40, 20);

    // Información del Cliente
    doc.autoTable({
      startY: 45,
      head: [['Solicitante', 'Fecha Actual', 'Cliente', 'Nombre de Operario', 'Pedido']],
      body: [[
        order.solicitante.toUpperCase() || 'N/A',
        order.fecha || 'N/A',
        order.cliente.toUpperCase() || 'N/A',
        order.personaAsignada || 'N/A',
        order.pedido.toUpperCase() || 'N/A',
      ]],
      theme: 'grid',
      headStyles: { fillColor: [0, 51, 153], textColor: [255, 255, 255] },
      bodyStyles: { fontSize: 10 },
      styles: { cellPadding: 1, halign: 'center' },
    });

    // Espacio entre tablas
    const clientInfoHeight = doc.autoTable.previous.finalY;

    // Encabezado para Material Entrada
    const entradaHeader = "SALIDA DE MATERIAL";
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 51, 153);
    textWidth = doc.getTextWidth(entradaHeader);
    doc.text(entradaHeader, (pageWidth - textWidth) / 2, clientInfoHeight + 10);

    // Tabla para los detalles del trabajo (Material Entrada)
    const entradaColumn = ["Descripción", "Cantidad", "Código"];
    const entradaRows = order.detalles.map((item) => [
      item.descripcion || 'N/A',
      (item.cantidad != null && item.sobrante != null)
        ? (+item.cantidad + +item.sobrante)
        : 'N/A',
      item.codigoIngreso || 'N/A',
    ]);

    // Tabla de detalles de los items (Material Entrada)
    doc.autoTable(entradaColumn, entradaRows, {
      startY: clientInfoHeight + 15,
      theme: 'grid',
      headStyles: { fillColor: [0, 51, 153], textColor: [255, 255, 255] },
      bodyStyles: { fontSize: 10 },
      styles: { cellPadding: 1, halign: 'center' },
    });

    // Espacio para la nueva tabla de información adicional
    const itemsTableHeight = doc.autoTable.previous.finalY + 10;

    // Encabezado para Material Salida
    const salidaHeader = "ENTRADA DE MATERIAL";
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.setTextColor(0, 51, 153);
    textWidth = doc.getTextWidth(salidaHeader);
    doc.text(salidaHeader, (pageWidth - textWidth) / 2, itemsTableHeight);

    // Tabla para la información adicional (Material Salida)
    const salidaColumns = [
      "Código de Entrada",
      "Color Requerido",
      "Tipo de Proceso"
    ];

    const salidaRows = order.detalles.map((item) => [
      (item.codigoSalida ? item.codigoSalida.toUpperCase() : 'N/A'),
      item.colorRequerido || 'N/A',
      item.tipoProceso || 'N/A',
    ]);

    // Tabla de información adicional (Material Salida)
    doc.autoTable(salidaColumns, salidaRows, {
      startY: itemsTableHeight + 5,
      theme: 'grid',
      headStyles: { fillColor: [0, 51, 153], textColor: [255, 255, 255] },
      bodyStyles: { fontSize: 10 },
      styles: { cellPadding: 1, halign: 'center' },
    });

    // Espacio para observaciones
    const finalY = doc.autoTable.previous.finalY;
    doc.setFontSize(10);
    doc.text(`Observación Del Solitante: ${order.observacion1 ? order.observacion1.toUpperCase() : 'N/A'}`, margin, finalY + 15);
    doc.text(`Observación Del Pintor: ${order.observacion2 ? order.observacion2.toUpperCase() : 'N/A'}`, margin, finalY + 25);
    doc.setLineWidth(0.5);
    doc.line(margin, finalY + 21, pageWidth - margin, finalY + 21);

    // Agregar imágenes de las firmas
    doc.addImage(firmaRevisa, 'PNG', margin, finalY + 35, 35, 15);  // Firma "Revisa"
    doc.text("Revisa:", margin, finalY + 30);

    doc.addImage(firmaRecibe, 'PNG', pageWidth / 2, finalY + 35, 35, 15);  // Firma "Recibe"
    doc.text("Recibe:", pageWidth / 2, finalY + 30);

    // Guardar el PDF
    doc.save(`detalles_orden_P-${order.numeroTicket}.pdf`);  // Guarda el PDF con el número de ticket

    if (permittedEmails.includes(userEmail)) {
      updateOrderState(order.id); // Llamar a la función updateOrderState aquí
    }
  };


  const toggleDetails = (index) => {
    setActiveOrderIndex(activeOrderIndex === index ? null : index);
  };


  const getOrderBackgroundColor = (status) => {
    const upperStatus = status.toUpperCase()
    if (upperStatus === 'COMPLETADO') {
      return '#C0EBA6';
    } else if (upperStatus === 'RECHAZADO') {
      return '#FF8A8A'
    } else if (upperStatus === 'APROBADO') {
      return '#FFF4B5'
    } else if (upperStatus === 'SOLICITADO') {
      return '#C7C8CC'
    } else if (upperStatus === 'AJUSTE INVENTARIO') {
      return '#77CDFF'
    }
  };

  const mapEstado = (estado) => {
    switch (estado) {
      case 'aprobado':
        return 'EN PROCESO';
      case 'completado':
        return 'FABRICADO';
      case 'rechazado':
        return 'NO FABRICADO';
      case 'solicitado':
        return 'EN ESPERA';
      default:
        return estado;
    }
  };



  // Navegación entre páginas
  const handleNextPage = () => {
    if ((currentPage + 1) * ordersPerPage < orders.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };


  return (
    <div className="panel-container">
      <h2>Control de Órdenes Actuales Pintura</h2>
      {orders.slice(currentPage * ordersPerPage, (currentPage + 1) * ordersPerPage).map((order, orderIndex) => (
        <div key={order.id} className="order-cardboard" style={{ backgroundColor: getOrderBackgroundColor(order.estado) }}>
          <div className="order-header">
          <p><strong>Ticket:</strong> P-{order.numeroTicket}</p>
            <p><strong>Fecha:</strong> {order.fechaProgramada}</p>
            <p><strong>Cliente:</strong> {order.cliente.toUpperCase()}</p>
            <p><strong>Número De Pedido:</strong> {order.pedido.toUpperCase()}</p>
            <p><strong>Estado:</strong> {mapEstado(order.estado).toUpperCase()}</p>

            <button className="detail-btnboard" onClick={() => toggleDetails(orderIndex)}>
              {activeOrderIndex === orderIndex ? 'Ocultar Detalles' : 'Ver Detalles'}
            </button>
          </div>

          {activeOrderIndex === orderIndex && (
            <div className="details-sectionboard">
              {order.detalles.map((item, itemIndex) => (
                <div key={itemIndex} className="niple-detailboardp">
                  <p>
                    <strong>{item.descripcion}</strong> &nbsp;
                    &nbsp; Cantidad: <strong>{item.cantidad}</strong> &nbsp;
                    Código: <strong>{item.codigoIngreso}</strong> &nbsp;
                  </p>
                </div>
              ))}

              {/* Mostrar observacion3 solo si existe */}
              {(order.observacion2?.trim() || order.observacion3?.trim()) && (
                <div className="observation-section">
                  {order.observacion3?.trim() && (
                    <p><strong>NOTA DEL ADMINISTRADOR:</strong> {order.observacion3.toUpperCase()}</p>
                  )}
                  {order.observacion2?.trim() && (
                    <p><strong>NOTA DEL PINTOR:</strong> {order.observacion2.toUpperCase()}</p>
                  )}
                </div>
              )}


              {['APROBADO', 'COMPLETADO'].includes(order.estado.toUpperCase()) && order.personaAsignada && (
                <div className="assigned-person">
                  <p>
                    <strong>Persona Asignada:</strong> {order.personaAsignada}
                  </p>
                </div>
              )}

              <div className="status-and-pdf">
                <button
                  onClick={() => generatePDF(order)}
                  className="pdf-btn"
                  disabled={!(order.estado.toUpperCase() === 'COMPLETADO' || order.estado.toUpperCase() === 'AJUSTE INVENTARIO')} // Habilita si es COMPLETADO o AJUSTE INVENTARIO
                  style={{
                    opacity: (order.estado.toUpperCase() === 'COMPLETADO' || order.estado.toUpperCase() === 'AJUSTE INVENTARIO') ? 1 : 0.5, // Cambia la opacidad
                    cursor: (order.estado.toUpperCase() === 'COMPLETADO' || order.estado.toUpperCase() === 'AJUSTE INVENTARIO') ? 'pointer' : 'not-allowed' // Cambia el cursor
                  }}
                >
                  <FiDownload /> Descargar PDF
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
      {/* Controles de navegación */}
      <div className="pagination-controls">
        <button className="nav-btn" onClick={handlePreviousPage} disabled={currentPage === 0}>
          <MdArrowBackIos />
        </button>
        <button className="nav-btn" onClick={handleNextPage} disabled={(currentPage + 1) * ordersPerPage >= orders.length}>
          <MdNavigateNext />
        </button>
      </div>
    </div>
  );
}