// src/components/DeniedAccessModal.js
import React from 'react';
import '../css/Header.css'; // Adjust the path as necessary

const DeniedAccessModal = ({ isVisible, onClose }) => {
  if (!isVisible) return null;

  return (
    <div className="modal-overlay-acess">
      <div className="modal-content-acess">
        <h2 className='modal-text-acess'>Acceso denegado</h2>
        <p className='modal-text-acess'>Por favor, ingresa con un correo de tuvacol.com</p>
        <button onClick={onClose} className='back-button5'>Cerrar</button>
      </div>
    </div>
  );
};

export default DeniedAccessModal;
