import React from 'react'

export default function AdminT() {
  return (
    <div>Página No Disponible
      <p>Lo sentimos, esta sección no se encuentra disponible en este momento.</p>
    </div>
    
  )
}
